import React from "react"

import styled from "@emotion/styled"

const Wrapper = styled.figure`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

function Hero({ children, img }) {
  return (
    <>
      <div className="hero bg-black">
        <Wrapper>{img}</Wrapper>
        <div className="container-fluid h-100 d-flex align-items-center">
          <div className="row mb-4">
            <div className="col-12 col-md-8">{children}</div>
          </div>
        </div>
      </div>
    </>
  )
}

Hero.defaultProps = {}

Hero.propTypes = {}

export default Hero
