import React from "react"

import styled from "@emotion/styled"
import Checked from "../components/checked"

import Banner from "../components/banner"
import Pricing from "../components/pricing"

import SEO from "../components/seo"
import Testimonials from "../components/testimonials"
import HeroHome from "../components/heroHome"
import HeroImage from "../components/heroImage"
import TestimonialImage1 from "../components/testimonialImage1"
import TestimonialImage2 from "../components/testimonialImage2"
import TestimonialImage3 from "../components/testimonialImage3"

import StickyCTA from "../components/stickyCTA"
import OfferteLink from "../components/offerteLink"

const priceUsps = [
  "Snel en vertrouwelijk verhuizen",
  "Flexibel en Professioneel",
  "Offerte binnen 24 uur",
  "Kwalitatief en goedkoop",
]

const usps = [
  "Snel en vertrouwelijk verhuizen",
  "Flexibel en Professioneel",
  "Vakkundig en kwalitatief",
  "Voordelig/goedkoop verhuizen",
  "Verhuizen in Amsterdam",
  "Verhuizen in Nederland",
  "Verhuizen naar het buitenland",
  "Opslagruimte",
  "Montage service",
  "Spoed verhuizen",
]

const testimonials = [
  {
    title: "Verhuizing voor Claudia en Harm, veel plezier in de nieuwe woning!",
    image: <TestimonialImage1></TestimonialImage1>,
  },
  {
    title: "Zakelijke verhuizing voor het Rai in Amsterdam.",
    image: <TestimonialImage2></TestimonialImage2>,
  },
  {
    title: "Verhuizen vanuit Utrecht naar het Duitse Kassel!",
    image: <TestimonialImage3></TestimonialImage3>,
  },
]

const StyledUsps = styled.section``

const Page = () => {
  return (
    <>
      <SEO title="Snel en voordelig verhuizen" />
      <HeroHome img={<HeroImage />}>
        <h1 className="font-weight-bold">Snel en voordelig verhuizen</h1>
      </HeroHome>
      <StyledUsps className="bg-offwhite">
        <div className="container-fluid">
          <div className="usp-box--horizontal">
            <div className="row">
              <div className="col-12 col-md-8">
                <div className="usp-content--horizontal">
                  <ul className="list list--col-2">
                    {priceUsps.map((usp, i) => (
                      <li key={usp}>
                        <h5 className="font-weight-medium">
                          <Checked>{usp}</Checked>
                        </h5>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="usp-cta--horizontal">
                  <div className="d-inline-flex flex-column text-center">
                    <OfferteLink btnClass="btn-primary mb-2"></OfferteLink>
                    <a
                      className="link link-secondary"
                      href="https://wa.me/31622441648?text="
                    >
                      Of Whatsapp met ons
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledUsps>
      <section className="section bg-offwhite">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-12 col-md-7">
              <h1 className="font-weight-light mb-2">
                Verhuisdeal24.nl verhuizen in Amsterdam
              </h1>
              <h4 className="font-weight-light mb-3">
                Verhuisdeal24.nl verhuist uw goederen of huis, tegen een
                voordelige prijs van a-naar-b door heel Nederland. Ook helpen
                wij u verhuizen van en naar Nederland.
              </h4>
              <p className="mb-3">
                Naast onze verhuisservice. Helpen wij u ook met het opslaan van
                uw meubels in onze opslagruimte, ook helpen we u graag met
                montage service tijdens uw verhuizing. Wilt u toch graag zelf uw
                verhuizing samenstellen, dan kunt u een verhuislift of een extra
                verhuizer inhuren bij ons.
              </p>
              <p className="font-weight-bold mb-6 mb-md-0">
                Naast onze verhuisservice. Helpen wij u ook met het opslaan van
                uw meubels in onze opslagruimte, ook helpen we u graag met
                montage service tijdens uw verhuizing. Wilt u toch graag zelf uw
                verhuizing samenstellen, dan kunt u een verhuislift of een extra
                verhuizer inhuren bij ons.
              </p>
            </div>

            <div className="col-12 col-md-5 offset-lg-1 col-lg-4">
              <Pricing></Pricing>
            </div>
          </div>
        </div>
      </section>

      <section className="section pt-0 bg-offwhite">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-12">
              <h3 className="font-weight-medium mb-4">
                Verhuizen is waar wij goed in zijn!!!
              </h3>
              <ul className="list list--col-2">
                {usps.map((usp, i) => (
                  <li key={usp}>
                    <h5 className="font-weight-medium">
                      <Checked>{usp}</Checked>
                    </h5>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Testimonials testimonials={testimonials} variant></Testimonials>
      <Banner />
      <StickyCTA></StickyCTA>
    </>
  )
}

export default Page
